import { ComboboxParsedItemGroup, Stack } from '@mantine/core';
import { VMultiSelect } from '@vision/ui/components';
import { useAppSelector, useReportCombinationMulti } from '@vision/ui/hooks';
import {
  ReportChartGroupCompare,
  ReportChartGroupCompareConfig,
  ReportsDetailGraphicSchemaValues,
} from '@vision/ui/interfaces';
import { TableChart1Measures } from '@vision/ui/pages/ReportsDetailGraphic/constants/table-chart1';
import { convertToSelectGroupItems } from '@vision/ui/pages/ReportsDetailGraphic/utils';
import { selectedChartGroupItemSelector } from '@vision/ui/store';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import { ReportsDetailGraphicConfigurationValueCompare } from '../../../ReportsDetailGraphicConfigurationValue';
import { ReportsDetailGraphicConfigurationValueHeader } from '../../../ReportsDetailGraphicConfigurationValueHeader';

export function TableChart1ChartValueComponent() {
  const { t } = useTranslation(['translation', 'page-reports']);
  const selectedChartGroupItem = useAppSelector(selectedChartGroupItemSelector);
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const reportCombination: ReportChartGroupCompareConfig[] = useReportCombinationMulti(
    selectedChartGroupItem?.type,
    formik.values?.selectedMultipleMeasure,
  );

  const compareSelectItems: ComboboxParsedItemGroup[] = useMemo(() => {
    if (!reportCombination) {
      return [];
    }

    const allCompareItems: ReportChartGroupCompare[] = reportCombination.reduce((acc, curr) => {
      return [...acc, ...curr.compareItems];
    }, []);

    // We group allCompareItems by type, combine the items in each group and return them.
    const allCompareItemsGrouped = allCompareItems.reduce((acc, curr) => {
      const existingGroup = acc.find((item) => item.type === curr.type);
      if (existingGroup) {
        // Combine the items in the group. but same items should not be duplicated.
        existingGroup.items = Array.from(new Set([...existingGroup.items, ...curr.items]));
      } else {
        acc.push({ type: curr.type, items: [...curr.items] });
      }
      return acc;
    }, [] as ReportChartGroupCompare[]);

    const commonItems = allCompareItemsGrouped.filter((item) => {
      const existAll = reportCombination.every((r) => r.compareItems.some((i) => i.type === item.type));
      return existAll;
    });

    return convertToSelectGroupItems(commonItems, 'reportCompareGroupType', 'reportCompareGroupItemType');
  }, [reportCombination]);

  const measurementSelectItems = useMemo(() => {
    if (!selectedChartGroupItem) {
      return [];
    }

    return convertToSelectGroupItems(
      TableChart1Measures,
      'reportMeasurementsGroupType',
      'reportMeasurementsGroupItemType',
    );
  }, [selectedChartGroupItem]);

  // When the selected compare is changed, we need to check if the selected compare is in the compareSelectItems.
  useDeepCompareEffect(() => {
    if (formik.values.selectedCompare.length) {
      const items = compareSelectItems.flatMap((item) => item.items).map((item) => item.value);

      if (!items.includes(formik.values.selectedCompare[0])) {
        formik.setFieldValue('selectedCompare', []);
      }
    }
  }, [compareSelectItems]);

  return (
    <Stack gap={20}>
      <Stack gap={10}>
        <ReportsDetailGraphicConfigurationValueHeader label={t('page-reports:measurement')} />

        <VMultiSelect
          id="report-measurement-multi-select"
          searchable={true}
          data-testid="input-report-measurement-multi-select"
          data={measurementSelectItems}
          clearable={true}
          withSelectAll={false}
          label={t('page-reports:measurement')}
          withAsterisk={true}
          value={formik.values?.selectedMultipleMeasure}
          onChange={(value) => formik.setFieldValue('selectedMultipleMeasure', value)}
          placeholder={formik.values.selectedMultipleMeasure?.length < 1 ? t('translation:search') : null}
          error={translateErrorMessage(formik, 'selectedMultipleMeasure')}
          maxValues={4}
          withCloseDropdownMaxValue={true}
        />
      </Stack>

      <ReportsDetailGraphicConfigurationValueCompare
        labelKey="page-reports:compare"
        compareSelectItems={compareSelectItems}
        showIcon={false}
        disabled={!formik.values.selectedMultipleMeasure?.length || formik.values.selectedMultipleMeasure.length < 1}
      />
    </Stack>
  );
}
