import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { axiosBaseQuery } from './base.service';
import { FlowTemplate } from '../interfaces';

export const FlowTemplatesService = createApi({
  reducerPath: 'FlowTemplatesService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getFlowTemplates: builder.query<FlowTemplate[], void>({
      query: () => ({
        url: `/v1/flow_templates`,
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const { useGetFlowTemplatesQuery } = FlowTemplatesService;
