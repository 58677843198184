import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  Account,
  AccountSettingsResponse,
  ApiResponse,
  HttpBaseRequest,
  SchemaField,
  SecuritySettingRequest,
  SecuritySettingResponse,
  User,
  Vertical,
} from '@vision/ui/interfaces';
import { buildEndpointWithQuery, createHttpExtraOptions } from '../utils';
import { axiosBaseQuery } from './base.service';

export const AccountService = createApi({
  reducerPath: 'AccountService',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['getAccountSettings'],
  endpoints: (builder) => ({
    accountFromCode: builder.query<Account, HttpBaseRequest<{ accountCode: string }>>({
      query: ({ accountCode }) => ({
        url: `/public/v1/accounts/${accountCode}`,
        method: HttpMethod.GET,
      }),
      keepUnusedDataFor: 0,
    }),
    accountCompleteLogin: builder.mutation<User, HttpBaseRequest<{ token: string }>>({
      query: ({ token }) => ({
        url: `/v1/complete_login`,
        method: HttpMethod.POST,
        data: { token },
      }),
    }),
    getAccountSettings: builder.query<AccountSettingsResponse, HttpBaseRequest<{ nodeId: string }>>({
      query: ({ nodeId }) => ({
        url: `/v1/accounts/${nodeId}/settings`,
        method: HttpMethod.GET,
      }),
      providesTags: ['getAccountSettings'],
    }),
    schemaFields: builder.query<SchemaField[], HttpBaseRequest<{ accountId: string }>>({
      query: ({ accountId }) => ({
        url: `/v1/accounts/${accountId}/schema_fields`,
        method: HttpMethod.GET,
      }),
      keepUnusedDataFor: 0,
    }),
    getSecuritySettings: builder.query<SecuritySettingResponse, HttpBaseRequest<{ accountId: string }>>({
      query: ({ accountId }) => ({
        url: `/v1/accounts/${accountId}/security_settings`,
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
    updateSecuritySettings: builder.mutation<SecuritySettingResponse, HttpBaseRequest<SecuritySettingRequest>>({
      query: (data) => ({
        url: `/v1/accounts/${data.accountId}/security_settings/${data.id}/update`,
        method: HttpMethod.POST,
        data: data,
      }),
    }),
    crateSecuritySettings: builder.mutation<SecuritySettingResponse, HttpBaseRequest<SecuritySettingRequest>>({
      query: (data) => ({
        url: `/v1/accounts/${data.accountId}/security_settings/`,
        method: HttpMethod.POST,
        data: data,
      }),
    }),
    verticals: builder.query<ApiResponse<Vertical[]>, HttpBaseRequest<{ query: string; accountId: string }>>({
      query: ({ query, accountId }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${accountId}/verticals`, query),
        method: HttpMethod.GET,
      }),
    }),
    addVertical: builder.mutation<ApiResponse<Vertical[]>, HttpBaseRequest<{ vertical: Vertical; accountId: string }>>({
      query: ({ vertical, accountId }) => ({
        url: `/v2/accounts/${accountId}/verticals`,
        method: HttpMethod.POST,
        data: vertical,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.vertical.{{status}}',
            autoHandleError: false,
          },
        }),
      }),
    }),
    updateVertical: builder.mutation<
      ApiResponse<Vertical[]>,
      HttpBaseRequest<{ vertical: Vertical; accountId: string }>
    >({
      query: ({ vertical, accountId }) => ({
        url: `/v2/accounts/${accountId}/verticals/${vertical.id}`,
        method: HttpMethod.POST,
        data: vertical,
      }),
    }),
    deleteVertical: builder.mutation<
      ApiResponse<Vertical[]>,
      HttpBaseRequest<{ verticalId: string; accountId: string }>
    >({
      query: ({ verticalId, accountId }) => ({
        url: `/v2/accounts/${accountId}/verticals/${verticalId}/delete`,
        method: HttpMethod.POST,
      }),
    }),
  }),
});

export const {
  useAccountCompleteLoginMutation,
  useAccountFromCodeQuery,
  useAddVerticalMutation,
  useCrateSecuritySettingsMutation,
  useDeleteVerticalMutation,
  useGetSecuritySettingsQuery,
  useLazyVerticalsQuery,
  useSchemaFieldsQuery,
  useUpdateSecuritySettingsMutation,
  useUpdateVerticalMutation,
  useLazyGetAccountSettingsQuery,
} = AccountService;
