import { TreeSelectItemType } from '@vision/ui/interfaces';
import { convertToList, convertToTree, ensureArray, findItemFromTree } from '@vision/ui/utils';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface TreeSelectContextValues {
  handleSearchTree: (searchText: string) => TreeSelectItemType[];
  handleToggleSelect: (item: TreeSelectItemType) => void;
  selectedTreeSelectItems: TreeSelectItemType[];
  treeSelectItems: TreeSelectItemType[];
  handleToggleSelectAllMainCategories: () => void;
}

export const TreeSelectContext = createContext<TreeSelectContextValues>({
  handleSearchTree: null,
  handleToggleSelect: null,
  selectedTreeSelectItems: [],
  treeSelectItems: [],
  handleToggleSelectAllMainCategories: null,
});

function defaultMapper(item: TreeSelectItemType) {
  return item;
}

export interface TreeSelectContextProviderProps<T> {
  items: T[];
  mapper: (item: T) => TreeSelectItemType;
  onChange: (value: string[]) => void;
  value: string[];
  withSubCategories?: boolean;
}

export function TreeSelectContextProvider<T>({
  children,
  items,
  mapper,
  onChange,
  value,
  withSubCategories,
}: React.PropsWithChildren<TreeSelectContextProviderProps<T>>) {
  const [treeSelectItems, setTreeSelectItems] = useState<TreeSelectItemType[]>([]);

  const flatTreeSelectItems = useMemo(() => {
    return ensureArray(items).map(mapper);
  }, [items]);

  const selectedTreeSelectItems = useMemo(() => {
    return flatTreeSelectItems.filter((item) => value.includes(item.id));
  }, [value, flatTreeSelectItems]);

  const handleToggleSelect = (treeItem: TreeSelectItemType) => {
    const exist = selectedTreeSelectItems.some((item) => item.id === treeItem.id);
    const allItems = convertToList(treeItem).filter((item) => !item.disabled);

    if (exist) {
      const filtered = selectedTreeSelectItems
        .filter((item) => !allItems.some((childItem) => item.id === childItem.id))
        .map((item) => item.id);
      onChange(filtered);
    } else {
      if (withSubCategories) {
        const updatedList = [...new Set([...value, ...allItems.map((item) => item.id)])];
        onChange(updatedList);
      } else {
        const updatedList = [...new Set([...value, treeItem.id])];
        onChange(updatedList);
      }
    }
  };

  const handleToggleSelectAllMainCategories = () => {
    /*if (withSubCategories) {
      // bütün kategorileri seçileceğinden dolayı bütün elemanları seçilmesi gerekli
      const allItems = flatTreeSelectItems.map((item) => item.id);
      const updatedList = [...new Set([...value, ...allItems])];
      onChange(updatedList);
      return;
    }*/

    const allMainItems = treeSelectItems.map((item) => item.id);
    const selectedAllMainItems = allMainItems.every((item) => value.includes(item));

    if (selectedAllMainItems) {
      const filtered = value.filter((item) => !allMainItems.includes(item));
      onChange(filtered);
      return;
    }
    const updatedList = [...new Set([...allMainItems])];
    onChange(updatedList);
  };

  const handleSearchTree = (searchText: string) => {
    const filtered = flatTreeSelectItems
      .filter((item) => item.label.toLowerCase().includes(searchText))
      .map((item) => ({ ...item, children: [] }));
    return convertToTree(filtered, defaultMapper);
  };

  const contextValues: TreeSelectContextValues = useMemo(
    () => ({
      handleSearchTree,
      handleToggleSelect,
      selectedTreeSelectItems,
      treeSelectItems,
      handleToggleSelectAllMainCategories,
    }),
    [
      handleSearchTree,
      handleToggleSelect,
      selectedTreeSelectItems,
      treeSelectItems,
      handleToggleSelectAllMainCategories,
    ],
  );

  useEffect(() => {
    const mappedItems = convertToTree(ensureArray(items), mapper);
    setTreeSelectItems(mappedItems);
  }, [items, mapper]);

  useEffect(() => {
    if (selectedTreeSelectItems.length > 0 && withSubCategories) {
      // seçilmiş olan kategorileri tree yapısına çevir
      const selectedTopLevelItems = convertToTree(selectedTreeSelectItems, defaultMapper);

      let allItems: TreeSelectItemType[] = [];
      // tree halinde en üst seviyedeki kategorileri al ve altındaki tüm kategorileri al
      selectedTopLevelItems.forEach((item) => {
        const itemWithChildren = findItemFromTree(treeSelectItems, item.id);
        if (itemWithChildren) {
          allItems = [...allItems, ...convertToList(itemWithChildren).filter((row) => !row.disabled)];
        }
      });

      const updatedList = [...new Set([...value, ...allItems.map((item) => item.id)])];
      onChange(updatedList);
    }
  }, [withSubCategories]);

  return <TreeSelectContext.Provider value={contextValues}>{children}</TreeSelectContext.Provider>;
}

export function useTreeSelectContext() {
  return useContext(TreeSelectContext);
}
