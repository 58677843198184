import { ComboboxItemGroup } from '@mantine/core';
import { If, VMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { QuestionStyle, ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetFlowsQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, ensureArray, getBodyText, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

const RESPONSE_QUESTION_TYPES: QuestionStyle[] = ['text', 'textarea', 'customer_schema'];

interface ReportsDetailGraphicCompareQuestionResponseProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareQuestionResponse({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareQuestionResponseProps) {
  const { i18n, t } = useTranslation(['translation', 'page-reports']);
  const accountId = useSelectedAccountId();
  const { data: flows, isFetching: isFetchingFlows } = useGetFlowsQuery({
    nodeId: accountId,
    includeQuestionOptions: true,
    includeQuestions: true,
  });

  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const isFlowSelected = formik.values.selectedCompareCriteria.selectedQuestionResponseFlowIds.length > 0;

  // just get the flows that have text or textarea questions
  const filteredFlows = useMemo(() => {
    if (!flows) {
      return [];
    }

    return flows.filter((flow) =>
      ensureArray(flow.questions).some(
        (question) => !!question.body && RESPONSE_QUESTION_TYPES.includes(question.style),
      ),
    );
  }, [flows]);

  const selectItems = useMemo(
    () =>
      convertArrayToSelectItems(filteredFlows, (item) => ({
        value: item.id,
        label: item.name,
      })),
    [filteredFlows],
  );

  const selectedFlows = useMemo(() => {
    if (!flows) {
      return [];
    }

    return formik.values.selectedCompareCriteria.selectedQuestionResponseFlowIds.map((flowId) =>
      flows.find((item) => item.id === flowId),
    );
  }, [flows, formik.values.selectedCompareCriteria.selectedQuestionResponseFlowIds]);

  const flowQuestionSelectItems = useMemo(() => {
    return selectedFlows.map((item) => {
      return {
        group: item.name,
        items: ensureArray(item.questions)
          .filter((question) => !!question.body && RESPONSE_QUESTION_TYPES.includes(question.style))
          .map((question) => ({
            label: getBodyText(question.body, i18n.language) || t('no-question-title'),
            value: question.id,
          })),
      } as ComboboxItemGroup;
    });
  }, [selectedFlows, formik.values.selectedCompareCriteria.selectedQuestionResponseFlowIds]);

  const handleSelectFlow = (value: string[]) => {
    const newSelectedFlows = value.map((flowId) => flows.find((item) => item.id === flowId));

    const newselectedQuestionResponseQuestionIds =
      formik.values.selectedCompareCriteria.selectedQuestionResponseQuestionIds.filter((selectedQuestionId) =>
        newSelectedFlows.some((flow) => flow?.questions?.some((question) => question.id === selectedQuestionId)),
      );

    formik.setValues({
      ...formik.values,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        selectedQuestionResponseFlowIds: value,
        selectedQuestionResponseQuestionIds: newselectedQuestionResponseQuestionIds,
      },
    });
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VMultiSelect
        id="report-compare-flows"
        data-testid="input-report-compare-flows"
        label={t('flows')}
        loading={isFetchingFlows}
        placeholder={isFetchingFlows ? t('loading') : t('search')}
        isValuesPillLoading={isFetchingFlows}
        data={selectItems}
        searchable={true}
        value={formik.values.selectedCompareCriteria.selectedQuestionResponseFlowIds}
        onChange={handleSelectFlow}
        onBlur={formik.handleBlur}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionResponseFlowIds')}
        withAsterisk={required}
      />

      <If value={isFlowSelected}>
        <VMultiSelect
          id="report-compare-flow-question"
          data-testid="input-report-compare-flow-question"
          label={t('question')}
          data={flowQuestionSelectItems}
          searchable={true}
          loading={isFetchingFlows}
          placeholder={isFetchingFlows ? t('loading') : t('search')}
          isValuesPillLoading={isFetchingFlows}
          value={formik.values.selectedCompareCriteria.selectedQuestionResponseQuestionIds}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionResponseQuestionIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionResponseQuestionIds')}
          withAsterisk={required}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
