// Please do not change casing of enum values, as they are used in this way in the backend.

export enum ChannelType {
  CATI = 'Cati',
  EMAIL = 'Mail',
  KIOSK = 'Kiosk',
  LINK = 'Link',
  MOBILE = 'Mobile',
  PUSH_NOTIFICATION = 'PushNotification',
  SMS = 'Sms',
  WEB_WIDGET = 'Web',
  WHATSAPP = 'Whatsapp',
  APP_STORE = 'AppStore',
  GOOGLE_PLAY = 'GooglePlay',
  IVR = 'Ivr',
  SIKAYET_VAR = 'SikayetVar',
}
