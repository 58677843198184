import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { GeoChart1CompareRenderer } from '../../components';
import { DefaultReportChartThemeFont, extendDefaultChartValidationSchema } from '../default-chart.config';
import { GeoChart1Measures } from './geo-chart1.measures';

export const GeoChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema({}, false),
  measures: GeoChart1Measures,
  compareRendererComponent: GeoChart1CompareRenderer,
  compareMultiple: true,
  compareHeader: {
    labelKey: 'page-reports:compare',
    showIcon: false,
  },
  measureHeader: {
    labelKey: 'page-reports:measurement',
    showIcon: false,
  },
  customize: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
  },
  defaultTheme: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
  },
};
