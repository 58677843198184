import { ReportType } from '@vision/ui/enums';
import {
  CustomIconBarChart1,
  CustomIconBarChart2,
  CustomIconBarChart3,
  CustomIconBarChart4,
  CustomIconBarChart5,
  CustomIconCardChart1,
  CustomIconFunnelChart1,
  CustomIconGaugeChart1,
  CustomIconGeoChart1,
  CustomIconHeatmapChart1,
  CustomIconPieChart1,
  CustomIconPieChart2,
  CustomIconPieChart3,
  CustomIconRadarChart1,
  CustomIconScatterChart1,
  CustomIconTableChart1,
  CustomIconTreeMapChart1,
  CustomIconTreeMapChart2,
  CustomIconTrendChart1,
  CustomIconTrendChart2,
  CustomIconTrendChart3,
  CustomIconTrendChart4,
  CustomIconWordCloudChart1,
} from '@vision/ui/icons';
import BarChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/bar-chart-1.png';
import BarChart2Placeholder from '@vision/ui/images/reports/chart-placeholders/bar-chart-2.png';
import BarChart3Placeholder from '@vision/ui/images/reports/chart-placeholders/bar-chart-3.png';
import BarChart4Placeholder from '@vision/ui/images/reports/chart-placeholders/bar-chart-4.png';
import CardChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/card-chart-1.png';
import FunnelChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/funnel-chart-1.png';
import GaugeChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/gauge-chart-1.png';
import GeoChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/geo-chart-1.png';
import HeatmapChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/heatmap-chart-1.png';
import PieChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/pie-chart-1.png';
import PieChart2Placeholder from '@vision/ui/images/reports/chart-placeholders/pie-chart-2.png';
import PieChart3Placeholder from '@vision/ui/images/reports/chart-placeholders/pie-chart-3.png';
import RadarChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/radar-chart-1.png';
import ScatterChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/scatter-chart-1.png';
import TableChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/table-chart-1.png';
import TreeMapChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/tree-map-chart-1.png';
import TrendChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/trend-chart-1.png';
import TrendChart2Placeholder from '@vision/ui/images/reports/chart-placeholders/trend-chart-2.png';
import TrendChart3Placeholder from '@vision/ui/images/reports/chart-placeholders/trend-chart-3.png';
import TrendChart4Placeholder from '@vision/ui/images/reports/chart-placeholders/trend-chart-4.png';
import WordCloudChart1Placeholder from '@vision/ui/images/reports/chart-placeholders/word-cloud-chart-1.png';
import BarChart1Preview from '@vision/ui/images/reports/chart-previews/bar-chart-1.png';
import BarChart2Preview from '@vision/ui/images/reports/chart-previews/bar-chart-2.png';
import BarChart3Preview from '@vision/ui/images/reports/chart-previews/bar-chart-3.png';
import BarChart4Preview from '@vision/ui/images/reports/chart-previews/bar-chart-4.png';
import CardChart1Preview from '@vision/ui/images/reports/chart-previews/card-chart-1.png';
import FunnelChart1Preview from '@vision/ui/images/reports/chart-previews/funnel-chart-1.png';
import GaugeChart1Preview from '@vision/ui/images/reports/chart-previews/gauge-chart-1.png';
import GeoChart1Preview from '@vision/ui/images/reports/chart-previews/geo-chart-1.png';
import HeatmapChart1Preview from '@vision/ui/images/reports/chart-previews/heatmap-chart-1.png';
import PieChart1Preview from '@vision/ui/images/reports/chart-previews/pie-chart-1.png';
import PieChart2Preview from '@vision/ui/images/reports/chart-previews/pie-chart-2.png';
import PieChart3Preview from '@vision/ui/images/reports/chart-previews/pie-chart-3.png';
import RadarChart1Preview from '@vision/ui/images/reports/chart-previews/radar-chart-1.png';
import ScatterChart1Preview from '@vision/ui/images/reports/chart-previews/scatter-chart-1.png';
import TableChart1Preview from '@vision/ui/images/reports/chart-previews/table-chart-1.png';
import TreeMapChart1Preview from '@vision/ui/images/reports/chart-previews/tree-map-chart-1.png';
import TreeMapChart2Preview from '@vision/ui/images/reports/chart-previews/tree-map-chart-2.png';
import TrendChart1Preview from '@vision/ui/images/reports/chart-previews/trend-chart-1.png';
import TrendChart2Preview from '@vision/ui/images/reports/chart-previews/trend-chart-2.png';
import TrendChart3Preview from '@vision/ui/images/reports/chart-previews/trend-chart-3.png';
import TrendChart4Preview from '@vision/ui/images/reports/chart-previews/trend-chart-4.png';
import WordCloud1Preview from '@vision/ui/images/reports/chart-previews/word-cloud-chart-1.png';
import { ReportChartGroup, ReportChartGroupItem, ReportChartGroupItemType } from '@vision/ui/interfaces';
import {
  BarChart1,
  BarChart2,
  BarChart3,
  BarChart4,
  BarChart5,
  CardChart1,
  FunnelChart1,
  GaugeChart1,
  GeoChart1,
  HeatMapChart1,
  PieChart1,
  PieChart2,
  PieChart3,
  RadarChart1,
  ScatterChart1,
  TableChart1,
  TreeMapChart1,
  TreeMapChart2,
  TrendChart1,
  TrendChart2,
  TrendChart3,
  TrendChart4,
  WordCloudChart1,
} from '../charts';
import { BarChart1DefaultConfiguration } from './bar-chart1';
import { BarChart2DefaultConfiguration } from './bar-chart2';
import { BarChart3DefaultConfiguration } from './bar-chart3';
import { BarChart4DefaultConfiguration } from './bar-chart4';
import { BarChart5DefaultConfiguration } from './bar-chart5';
import { CardChart1DefaultConfiguration } from './card-chart1';
import {
  ExBarChart1DefaultConfiguration,
  ExBarChart2DefaultConfiguration,
  ExBarChart4DefaultConfiguration,
  ExBarChart5DefaultConfiguration,
  EXCardChart1DefaultConfiguration,
  ExPieChart1DefaultConfiguration,
  ExPieChart2DefaultConfiguration,
  ExPieChart3DefaultConfiguration,
  ExTrendChart1DefaultConfiguration,
  ExTrendChart2DefaultConfiguration,
  EXTrendChart3DefaultConfiguration,
  EXTrendChart4DefaultConfiguration,
} from './ex-charts';
import { FunnelChart1DefaultConfiguration } from './funnel-chart1';
import { GaugeChart1DefaultConfiguration } from './gauge-chart1';
import { GeoChart1DefaultConfiguration } from './geo-chart1';
import { HeatMapChart1DefaultConfiguration } from './heat-map-chart1';
import { PieChart1DefaultConfiguration } from './pie-chart1';
import { PieChart2DefaultConfiguration } from './pie-chart2';
import { PieChart3DefaultConfiguration } from './pie-chart3';
import { RadarChart1DefaultConfiguration } from './radar-chart1';
import { ScatterChart1DefaultConfiguration } from './scatter-chart1';
import { TableChart1DefaultConfiguration } from './table-chart1';
import { TreeMapChart1DefaultConfiguration } from './tree-map-chart1';
import { TreeMapChart2DefaultConfiguration } from './tree-map-chart2';
import { TrendChart1DefaultConfiguration } from './trend-chart1';
import { TrendChart2DefaultConfiguration } from './trend-chart2';
import { TrendChart3DefaultConfiguration } from './trend-chart3';
import { TrendChart4DefaultConfiguration } from './trend-chart4';
import { WordCloudChart1DefaultConfiguration } from './word-cloud-chart1';

export const ReportCharts: ReportChartGroup[] = [
  {
    type: 'trend',
    items: [
      {
        icon: CustomIconTrendChart1,
        placeholderImageSrc: TrendChart1Placeholder,
        previewImageSrc: TrendChart1Preview,
        type: 'trend-chart-1',
        config: TrendChart1DefaultConfiguration,
        component: TrendChart1,
      },
      {
        icon: CustomIconTrendChart2,
        placeholderImageSrc: TrendChart2Placeholder,
        previewImageSrc: TrendChart2Preview,
        type: 'trend-chart-2',
        config: TrendChart2DefaultConfiguration,
        component: TrendChart2,
      },
      {
        icon: CustomIconTrendChart3,
        placeholderImageSrc: TrendChart3Placeholder,
        previewImageSrc: TrendChart3Preview,
        type: 'trend-chart-3',
        config: TrendChart3DefaultConfiguration,
        component: TrendChart3,
      },
      {
        icon: CustomIconTrendChart4,
        placeholderImageSrc: TrendChart4Placeholder,
        previewImageSrc: TrendChart4Preview,
        type: 'trend-chart-4',
        config: TrendChart4DefaultConfiguration,
        component: TrendChart4,
      },
    ],
  },
  {
    type: 'bar',
    items: [
      {
        icon: CustomIconBarChart1,
        placeholderImageSrc: BarChart1Placeholder,
        previewImageSrc: BarChart1Preview,
        type: 'bar-chart-1',
        config: BarChart1DefaultConfiguration,
        component: BarChart1,
      },
      {
        icon: CustomIconBarChart2,
        placeholderImageSrc: BarChart2Placeholder,
        previewImageSrc: BarChart2Preview,
        type: 'bar-chart-2',
        config: BarChart2DefaultConfiguration,
        component: BarChart2,
      },
      {
        icon: CustomIconBarChart3,
        placeholderImageSrc: BarChart3Placeholder,
        previewImageSrc: BarChart3Preview,
        type: 'bar-chart-3',
        config: BarChart3DefaultConfiguration,
        component: BarChart3,
      },
      {
        icon: CustomIconBarChart4,
        placeholderImageSrc: BarChart4Placeholder,
        previewImageSrc: BarChart4Preview,
        type: 'bar-chart-4',
        config: BarChart4DefaultConfiguration,
        component: BarChart4,
      },
      {
        icon: CustomIconBarChart5,
        placeholderImageSrc: BarChart4Placeholder,
        previewImageSrc: BarChart4Preview,
        type: 'bar-chart-5',
        config: BarChart5DefaultConfiguration,
        component: BarChart5,
      },
    ],
  },
  {
    type: 'pie',
    items: [
      {
        icon: CustomIconPieChart1,
        placeholderImageSrc: PieChart1Placeholder,
        previewImageSrc: PieChart1Preview,
        type: 'pie-chart-1',
        config: PieChart1DefaultConfiguration,
        component: PieChart1,
      },
      {
        icon: CustomIconPieChart2,
        placeholderImageSrc: PieChart2Placeholder,
        previewImageSrc: PieChart2Preview,
        type: 'pie-chart-2',
        config: PieChart2DefaultConfiguration,
        component: PieChart2,
      },
      {
        icon: CustomIconPieChart3,
        placeholderImageSrc: PieChart3Placeholder,
        previewImageSrc: PieChart3Preview,
        type: 'pie-chart-3',
        config: PieChart3DefaultConfiguration,
        component: PieChart3,
      },
    ],
  },
  {
    type: 'scatter',
    items: [
      {
        icon: CustomIconScatterChart1,
        placeholderImageSrc: ScatterChart1Placeholder,
        previewImageSrc: ScatterChart1Preview,
        type: 'scatter-chart-1',
        config: ScatterChart1DefaultConfiguration,
        component: ScatterChart1,
      },
    ],
  },
  {
    type: 'geo',
    items: [
      {
        icon: CustomIconGeoChart1,
        placeholderImageSrc: GeoChart1Placeholder,
        previewImageSrc: GeoChart1Preview,
        type: 'geo-chart-1',
        config: GeoChart1DefaultConfiguration,
        component: GeoChart1,
      },
    ],
  },
  {
    type: 'radar',
    items: [
      {
        icon: CustomIconRadarChart1,
        placeholderImageSrc: RadarChart1Placeholder,
        previewImageSrc: RadarChart1Preview,
        type: 'radar-chart-1',
        config: RadarChart1DefaultConfiguration,
        component: RadarChart1,
      },
    ],
  },
  {
    type: 'heatmap',
    items: [
      {
        icon: CustomIconHeatmapChart1,
        placeholderImageSrc: HeatmapChart1Placeholder,
        previewImageSrc: HeatmapChart1Preview,
        type: 'heatmap-chart-1',
        config: HeatMapChart1DefaultConfiguration,
        component: HeatMapChart1,
      },
    ],
  },
  {
    type: 'tree-map',
    items: [
      {
        icon: CustomIconTreeMapChart1,
        placeholderImageSrc: TreeMapChart1Placeholder,
        previewImageSrc: TreeMapChart1Preview,
        type: 'tree-map-chart-1',
        config: TreeMapChart1DefaultConfiguration,
        component: TreeMapChart1,
      },
      {
        icon: CustomIconTreeMapChart2,
        placeholderImageSrc: TreeMapChart1Placeholder,
        previewImageSrc: TreeMapChart2Preview,
        type: 'tree-map-chart-2',
        config: TreeMapChart2DefaultConfiguration,
        component: TreeMapChart2,
      },
    ],
  },
  {
    type: 'funnel',
    items: [
      {
        icon: CustomIconFunnelChart1,
        placeholderImageSrc: FunnelChart1Placeholder,
        previewImageSrc: FunnelChart1Preview,
        type: 'funnel-chart-1',
        config: FunnelChart1DefaultConfiguration,
        component: FunnelChart1,
      },
    ],
  },
  {
    type: 'gauge',
    items: [
      {
        icon: CustomIconGaugeChart1,
        placeholderImageSrc: GaugeChart1Placeholder,
        previewImageSrc: GaugeChart1Preview,
        type: 'gauge-chart-1',
        config: GaugeChart1DefaultConfiguration,
        component: GaugeChart1,
      },
    ],
  },
  {
    type: 'word-cloud',
    items: [
      {
        icon: CustomIconWordCloudChart1,
        placeholderImageSrc: WordCloudChart1Placeholder,
        previewImageSrc: WordCloud1Preview,
        type: 'word-cloud-chart-1',
        config: WordCloudChart1DefaultConfiguration,
        component: WordCloudChart1,
      },
    ],
  },
  {
    type: 'table',
    items: [
      {
        icon: CustomIconTableChart1,
        placeholderImageSrc: TableChart1Placeholder,
        previewImageSrc: TableChart1Preview,
        type: 'table-chart-1',
        config: TableChart1DefaultConfiguration,
        component: TableChart1,
      },
    ],
  },
  {
    type: 'card',
    items: [
      {
        icon: CustomIconCardChart1,
        placeholderImageSrc: CardChart1Placeholder,
        previewImageSrc: CardChart1Preview,
        type: 'card-chart-1',
        config: CardChart1DefaultConfiguration,
        component: CardChart1,
      },
    ],
  },
];

export const ExReportCharts: ReportChartGroup[] = [
  {
    type: 'trend',
    items: [
      {
        icon: CustomIconTrendChart1,
        placeholderImageSrc: TrendChart1Placeholder,
        previewImageSrc: TrendChart1Preview,
        type: 'trend-chart-1',
        config: ExTrendChart1DefaultConfiguration,
        component: TrendChart1,
      },
      {
        icon: CustomIconTrendChart2,
        placeholderImageSrc: TrendChart2Placeholder,
        previewImageSrc: TrendChart2Preview,
        type: 'trend-chart-2',
        config: ExTrendChart2DefaultConfiguration,
        component: TrendChart2,
      },
      {
        icon: CustomIconTrendChart3,
        placeholderImageSrc: TrendChart3Placeholder,
        previewImageSrc: TrendChart3Preview,
        type: 'trend-chart-3',
        config: EXTrendChart3DefaultConfiguration,
        component: TrendChart3,
      },
      {
        icon: CustomIconTrendChart4,
        placeholderImageSrc: TrendChart4Placeholder,
        previewImageSrc: TrendChart4Preview,
        type: 'trend-chart-4',
        config: EXTrendChart4DefaultConfiguration,
        component: TrendChart4,
      },
    ],
  },
  {
    type: 'bar',
    items: [
      {
        icon: CustomIconBarChart1,
        placeholderImageSrc: BarChart1Placeholder,
        previewImageSrc: BarChart1Preview,
        type: 'bar-chart-1',
        config: ExBarChart1DefaultConfiguration,
        component: BarChart1,
      },
      {
        icon: CustomIconBarChart2,
        placeholderImageSrc: BarChart2Placeholder,
        previewImageSrc: BarChart2Preview,
        type: 'bar-chart-2',
        config: ExBarChart2DefaultConfiguration,
        component: BarChart2,
      },
      {
        icon: CustomIconBarChart4,
        placeholderImageSrc: BarChart4Placeholder,
        previewImageSrc: BarChart4Preview,
        type: 'bar-chart-4',
        config: ExBarChart4DefaultConfiguration,
        component: BarChart4,
      },
      {
        icon: CustomIconBarChart5,
        placeholderImageSrc: BarChart4Placeholder,
        previewImageSrc: BarChart4Preview,
        type: 'bar-chart-5',
        config: ExBarChart5DefaultConfiguration,
        component: BarChart5,
      },
    ],
  },
  {
    type: 'heatmap',
    items: [
      {
        icon: CustomIconHeatmapChart1,
        placeholderImageSrc: HeatmapChart1Placeholder,
        previewImageSrc: HeatmapChart1Preview,
        type: 'heatmap-chart-1',
        config: HeatMapChart1DefaultConfiguration,
        component: HeatMapChart1,
      },
    ],
  },

  {
    type: 'pie',
    items: [
      {
        icon: CustomIconPieChart1,
        placeholderImageSrc: PieChart1Placeholder,
        previewImageSrc: PieChart1Preview,
        type: 'pie-chart-1',
        config: ExPieChart1DefaultConfiguration,
        component: PieChart1,
      },
      {
        icon: CustomIconPieChart2,
        placeholderImageSrc: PieChart2Placeholder,
        previewImageSrc: PieChart2Preview,
        type: 'pie-chart-2',
        config: ExPieChart2DefaultConfiguration,
        component: PieChart2,
      },
      {
        icon: CustomIconPieChart3,
        placeholderImageSrc: PieChart3Placeholder,
        previewImageSrc: PieChart3Preview,
        type: 'pie-chart-3',
        config: ExPieChart3DefaultConfiguration,
        component: PieChart3,
      },
    ],
  },
  {
    type: 'funnel',
    items: [
      {
        icon: CustomIconFunnelChart1,
        placeholderImageSrc: FunnelChart1Placeholder,
        previewImageSrc: FunnelChart1Preview,
        type: 'funnel-chart-1',
        config: FunnelChart1DefaultConfiguration,
        component: FunnelChart1,
      },
    ],
  },
  {
    type: 'table',
    items: [
      {
        icon: CustomIconTableChart1,
        placeholderImageSrc: TableChart1Placeholder,
        previewImageSrc: TableChart1Preview,
        type: 'table-chart-1',
        config: null,
        component: null,
      },
    ],
  },
  {
    type: 'card',
    items: [
      {
        icon: CustomIconCardChart1,
        placeholderImageSrc: CardChart1Placeholder,
        previewImageSrc: CardChart1Preview,
        type: 'card-chart-1',
        config: EXCardChart1DefaultConfiguration,
        component: CardChart1,
      },
    ],
  },
];

export const ReportChartsItems = ReportCharts.reduce(
  (acc, item) => [...acc, ...item.items],
  [] as ReportChartGroupItem[],
);

export const ExReportChartsItems = ExReportCharts.reduce(
  (acc, item) => [...acc, ...item.items],
  [] as ReportChartGroupItem[],
);

/*function findReportChartGroup(type: ReportChartGroupType) {
  return ReportCharts.find((item) => item.type === type);
}*/

/*export function findReportChartGroupItem(groupType: ReportChartGroupType, type: ReportChartGroupItemType) {
  const group = findReportChartGroup(groupType);
  return group.items.find((item) => item.type === type);
}*/

export function findReportChartGroupByGroupItemType(
  type: ReportChartGroupItemType,
  reportType?: ReportType,
): ReportChartGroup {
  if (reportType === ReportType.EX_REPORTS) {
    return ExReportCharts.find((item) => item.items.some((groupItem) => groupItem.type === type));
  }

  return ReportCharts.find((item) => item.items.some((groupItem) => groupItem.type === type));
}
