import { ComboboxItem } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { VMultiSelect, VMultiSelectProps } from '@vision/ui/components';
import { UseLazyLoadSelectOptions, useLazyLoadSelect } from '@vision/ui/hooks';
import { OptionsGroup } from '@vision/ui/interfaces';

interface VLazyLoadMultiSelectProps<RequestType, ResponseType>
  extends Omit<VMultiSelectProps, 'data'>,
    UseLazyLoadSelectOptions<RequestType, ResponseType> {
  onDataChange?: (data: ResponseType[]) => void;
  onSelectItemsChange?: (data: Array<ComboboxItem | OptionsGroup>) => void;
  searchKey?: string;
  showSelectedPillCountText?: boolean;
}

export function VLazyLoadMultiSelect<RequestType, ResponseType>({
  apiRequestParameters,
  disabledCondition,
  multiSelectItemMapper,
  onDataChange,
  onSelectItemsChange,
  searchable = true,
  singleSelectItemMapper,
  useLazyApiQueryFunction,
  searchKey,
  value,
  filterKey,
  showSelectedPillCountText,
  ...props
}: VLazyLoadMultiSelectProps<RequestType, ResponseType>) {
  const {
    allData,
    allDataSelectItems,
    contextValue: { currentPage, loadMore, totalPage },
    dataSelectItemsWithDisabledCondition,
    isFilterQueryLoading,
    isLoading,
    setSearchQuery,
    loadedDefaultData,
  } = useLazyLoadSelect({
    filterKey,
    searchKey,
    apiRequestParameters,
    disabledCondition,
    multiSelectItemMapper,
    singleSelectItemMapper,
    useLazyApiQueryFunction,
    defaultValues: value,
  });

  const handleLoadMore = () => {
    if (currentPage === totalPage) {
      return;
    }

    loadMore(currentPage + 1);
  };

  useDidUpdate(() => {
    onDataChange?.(allData);
  }, [allData]);

  useDidUpdate(() => {
    onSelectItemsChange?.(allDataSelectItems);
  }, [allDataSelectItems]);

  return (
    <VMultiSelect
      value={value}
      data={dataSelectItemsWithDisabledCondition}
      loading={isLoading || isFilterQueryLoading}
      onScrollToBottom={handleLoadMore}
      onSearchChange={setSearchQuery}
      searchable={searchable}
      withSelectAll={false}
      showAllSelectedPill={false}
      showSelectedPillCountText={showSelectedPillCountText}
      isValuesPillLoading={!loadedDefaultData}
      {...props}
    />
  );
}
