import { ComboboxParsedItemGroup, Stack } from '@mantine/core';
import { VSelect } from '@vision/ui/components';
import { useAppSelector, useReportCombinationMulti } from '@vision/ui/hooks';
import {
  ReportChartGroupCompare,
  ReportChartGroupCompareConfig,
  ReportChartGroupCompareItemType,
  ReportsDetailGraphicSchemaValues,
} from '@vision/ui/interfaces';
import { selectedChartGroupItemSelector } from '@vision/ui/store';
import { ensureArray, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { convertToSelectGroupItems, getReportChartCompareKey } from '../../../../utils';
import { ReportsDetailGraphicConfigurationValueHeader } from '../../../ReportsDetailGraphicConfigurationValueHeader';

export function PieChart2ValueComponent() {
  const { t } = useTranslation('page-reports');
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const selectedChartGroupItem = useAppSelector(selectedChartGroupItemSelector);

  const reportCombination: ReportChartGroupCompareConfig[] = useReportCombinationMulti(
    selectedChartGroupItem?.type,
    Array.from(new Set(ensureArray([formik.values.selectedMeasure]))),
  );

  const compareSelectItems: ComboboxParsedItemGroup[] = useMemo(() => {
    if (!reportCombination) {
      return [];
    }

    const allCompareItems: ReportChartGroupCompare[] = reportCombination.reduce((acc, curr) => {
      return [...acc, ...curr.compareItems];
    }, []);

    // We group allCompareItems by type, combine the items in each group and return them.
    const allCompareItemsGrouped = allCompareItems.reduce((acc, curr) => {
      const existingGroup = acc.find((item) => item.type === curr.type);
      if (existingGroup) {
        // Combine the items in the group. but same items should not be duplicated.
        existingGroup.items = Array.from(new Set([...existingGroup.items, ...curr.items]));
      } else {
        acc.push({ type: curr.type, items: [...curr.items] });
      }
      return acc;
    }, [] as ReportChartGroupCompare[]);

    const commonItems = allCompareItemsGrouped.filter((item) => {
      const existAll = reportCombination.every((r) => r.compareItems.some((i) => i.type === item.type));
      return existAll;
    });

    return convertToSelectGroupItems(commonItems, 'reportCompareGroupType', 'reportCompareGroupItemType');
  }, [reportCombination]);

  const handleSelectCompare = (value: ReportChartGroupCompareItemType, index: number) => {
    const newCompare: ReportChartGroupCompareItemType[] = [
      ...formik.values.selectedCompare.slice(0, index),
      value,
      ...formik.values.selectedCompare.slice(index + 1),
    ];

    const removedValue = formik.values.selectedCompare.filter((item) => !newCompare.includes(item));

    // Creating deprecated Formik values.
    const removedFormikValues = getReportChartCompareKey(removedValue).reduce((acc, item) => {
      return {
        ...acc,
        [item]: [],
      };
    }, {});

    formik.setValues({
      ...formik.values,
      selectedCompare: newCompare,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        ...removedFormikValues,
      },
    });
  };

  const firstCompareSelectItems = useMemo(() => {
    const selectedSecondCompare = formik.values.selectedCompare[1];

    if (selectedSecondCompare) {
      return compareSelectItems.map((group) => {
        return {
          ...group,
          items: group.items.filter((item) => item.value !== selectedSecondCompare),
        };
      });
    }

    return compareSelectItems;
  }, [compareSelectItems]);

  const secondCompareSelectItems = useMemo(() => {
    const selectedFirstCompare = formik.values.selectedCompare[0];

    if (selectedFirstCompare) {
      return compareSelectItems.map((group) => {
        return {
          ...group,
          items: group.items.filter((item) => item.value !== selectedFirstCompare),
        };
      });
    }

    return compareSelectItems;
  }, [compareSelectItems]);

  const CompareRendererComponent =
    formik.values.selectedCompare.length > 0 && selectedChartGroupItem.config.compareRendererComponent;

  return (
    <Stack gap={20}>
      <ReportsDetailGraphicConfigurationValueHeader label={t('innerLayerCompare')} />

      <VSelect
        id="report-compare-single"
        data-testid="input-report-compare"
        data={firstCompareSelectItems}
        label={t('compare')}
        withAsterisk={true}
        clearable={true}
        searchable={true}
        onChange={(value) => handleSelectCompare(value as ReportChartGroupCompareItemType, 0)}
        value={formik.values.selectedCompare[0] || null}
        error={translateErrorMessage(formik, 'selectedCompare')}
        disabled={!formik.values.selectedMeasure}
      />

      <ReportsDetailGraphicConfigurationValueHeader label={t('outerLayerCompare')} />

      <VSelect
        id="report-compare-single"
        data-testid="input-report-compare"
        data={secondCompareSelectItems}
        label={t('compare')}
        withAsterisk={true}
        clearable={true}
        searchable={true}
        onChange={(value) => handleSelectCompare(value as ReportChartGroupCompareItemType, 1)}
        value={formik.values.selectedCompare[1] || null}
        error={translateErrorMessage(formik, 'selectedCompare')}
        disabled={formik.values.selectedCompare.length < 1}
      />
      {CompareRendererComponent && <CompareRendererComponent compareItemTypes={formik.values.selectedCompare} />}
    </Stack>
  );
}
