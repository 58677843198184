import { faEllipsis, faEllipsisVertical, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, ActionIconProps, FloatingPosition, Loader, Menu } from '@mantine/core';
import { ensureArray, uuid } from '@vision/ui/utils';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { PermissionToggle } from '../PermissionToggle';
import classes from './ActionMenu.module.scss';

type ActionMenuItemFn<T> = (data?: T) => boolean;

export interface ActionMenuItem<T = any> {
  disabled?: boolean | ActionMenuItemFn<T>;
  hidden?: boolean | ActionMenuItemFn<T>;
  icon: IconDefinition;
  id?: string;
  label: string | React.ReactNode;
  loading?: boolean;
  onClick?(data?: T): void;
  permission?: string;
}

export interface ActionMenuProps<T = any> {
  closeOnItemClick?: boolean;
  dropdownClassName?: string;
  iconSize?: ActionIconProps['size'];
  iconVertical?: boolean;
  items: ActionMenuItem<T>[];
  menuTargetClassName?: string;
  offset?: number;
  onChange?(opened: boolean): void;
  opened?: boolean;
  position?: FloatingPosition;
  preventDefaultOnClick?: boolean;
}

export function ActionMenu({
  closeOnItemClick = true,
  dropdownClassName,
  iconSize = 40,
  iconVertical,
  items,
  menuTargetClassName,
  offset = -2,
  onChange,
  opened: openedProp,
  position = 'bottom-end',
  preventDefaultOnClick,
}: ActionMenuProps) {
  const [opened, setOpened] = useState(openedProp ?? false);

  const itemsWithKey: ActionMenuItem[] = useMemo(
    () => ensureArray(items).map((item) => ({ ...item, id: item.id || uuid() })),
    [items],
  );

  useEffect(() => {
    setOpened(openedProp);
  }, [openedProp]);

  useEffect(() => {
    onChange?.(opened);
  }, [opened]);

  return (
    <Menu
      onChange={setOpened}
      position={position}
      opened={opened}
      offset={offset}
      closeOnItemClick={closeOnItemClick}
      classNames={{
        arrow: classes.actionMenuArrow,
      }}
    >
      <Menu.Target>
        <ActionIcon
          className={clsx(classes.actionMenuTarget, menuTargetClassName)}
          data-testid="button-action-menu"
          variant={opened ? 'filled' : 'transparent'}
          color={opened ? 'blue' : 'dark'}
          size={iconSize}
          onClick={(event: React.MouseEvent) => {
            if (preventDefaultOnClick) {
              event.stopPropagation();
              event.preventDefault();
            }
          }}
        >
          <FontAwesomeIcon icon={iconVertical ? faEllipsisVertical : faEllipsis} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown className={clsx(classes.actionMenuDropdown, dropdownClassName)}>
        {itemsWithKey
          .filter((item) => (typeof item.hidden === 'function' ? !item.hidden() : !item.hidden))
          .map((item) => (
            <PermissionToggle key={item.id} value={item.permission}>
              <Menu.Item
                data-testid={`button-action-menu-item-${item.id}`}
                className={classes.actionMenuDropdownItem}
                classNames={{
                  itemLabel: classes.actionMenuItemLabel,
                }}
                onClick={(event: React.MouseEvent) => {
                  if (preventDefaultOnClick) {
                    event.stopPropagation();
                    event.preventDefault();
                  }
                  item?.onClick();
                }}
                disabled={typeof item.disabled === 'function' ? item.disabled() : item.disabled || item.loading}
              >
                {item.loading ? (
                  <Loader className={classes.actionMenuDropdownItemIcon} size={15} />
                ) : (
                  <FontAwesomeIcon className={classes.actionMenuDropdownItemIcon} icon={item.icon} fixedWidth={true} />
                )}
                {item.label}
              </Menu.Item>
            </PermissionToggle>
          ))}
      </Menu.Dropdown>
    </Menu>
  );
}
