import { faEarth } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Group, Stack, StackProps, Text, useMantineTheme } from '@mantine/core';
import { DateFormatter, If, Uppercase } from '@vision/ui/components';
import { ensureHexColor, getNameInitials } from '@vision/ui/utils';
import React from 'react';
import classes from './InfoCard.module.scss';

interface InfoCardProps extends StackProps {}

export function InfoCard({ children, ...rest }: React.PropsWithChildren<InfoCardProps>) {
  return (
    <Stack className={classes.infoCard} data-testid="info-card" gap={20} px={20} py={20} {...rest}>
      {children}
    </Stack>
  );
}

interface InfoCardHeaderProps {
  name: string;
  labels: string[];
  date?: string;
  avatarColor?: string;
  avatar?: string;
  avatarComponent?: React.ReactNode;
}

function InfoCardHeader({
  name,
  labels,
  date,
  avatarColor,
  avatar,
  avatarComponent,
  children,
}: React.PropsWithChildren<InfoCardHeaderProps>) {
  const theme = useMantineTheme();

  return (
    <Group justify="space-between">
      <Group gap={8}>
        {avatarComponent ? (
          avatarComponent
        ) : (
          <Avatar
            className={classes.avatar}
            size={48}
            src={avatar ?? ''}
            styles={{
              placeholder: {
                backgroundColor: ensureHexColor(avatarColor),
                color: theme.white,
              },
            }}
            radius="xl"
          >
            <Uppercase value={getNameInitials(name)} />
          </Avatar>
        )}
        <Stack>
          <Text fz="md" fw={700} c="white">
            {name}
          </Text>
          <Group gap={10} c="white">
            {labels?.map((label, index) => (
              <Text key={index} fz="xs">
                {label}
              </Text>
            ))}
          </Group>
        </Stack>
      </Group>

      {/* Customer detail data does not have last online date. So this block will never be displayed until it is provided. */}
      <If value={!!date}>
        <Group className={classes.lastSeen} gap={5} px={10} miw={185} h={30} justify="center">
          <FontAwesomeIcon icon={faEarth} color="white" />
          <Text size="xs" c="white">
            <DateFormatter value={date} variant="medium" />
          </Text>
        </Group>
      </If>
      {children}
    </Group>
  );
}

function InfoCardBody({ children }: React.PropsWithChildren) {
  return (
    <Stack className={classes.stack} gap={10} py={20} justify="flex-start">
      {children}
    </Stack>
  );
}

function InfoCardFooter({ children }: React.PropsWithChildren) {
  return children;
}

InfoCard.Header = InfoCardHeader;
InfoCard.Body = InfoCardBody;
InfoCard.Footer = InfoCardFooter;
